@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat-regular-webfont.woff2') format('woff2'),
       url('../fonts/montserrat-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat-black-webfont.woff2') format('woff2'),
       url('../fonts/montserrat-black-webfont.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat-bold-webfont.woff2') format('woff2'),
       url('../fonts/montserrat-bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('fonts/montserrat-italic-webfont.woff2') format('woff2'),
       url('fonts/montserrat-italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

body {
  background: #e2eaef;
  font-family: 'Montserrat', Helvetica, Arial, sans-serif;
  font-size: 20px;
  color: #000;
}

body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

h1, h2, h3, h4, h5 {
  margin: 0; 
}

header, nav, main, footer, section, article, div {
  box-sizing: border-box;
}

.header {
  height: 4em;
  background-color: #0d2436;
  border-bottom: 2px solid #e1472b;
  display: flex;

}

.header.open {
  border-bottom: none;
}

.header_inner {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.header__title {
  font-size: 1.5em;
  font-weight: 900;
  letter-spacing: .18em;
  padding-left: 10px;
  color: #ffffff;
  text-transform: uppercase;
  align-self: center;
}

nav.open {
  padding: 5px 0 5px;
  background-color: #0d2436;
  border-bottom: 2px solid #e1472b;
  display: flex;
  justify-content: center;
}

nav {
  display: none;
}

nav ul {
  margin: 0;
  padding: 0;
  list-style-type: none; 
}

nav ul li {
  line-height: 2;
  margin-bottom: 10px;
}

nav ul li a {
  display: block;
  width: 170px;
  font-size: 18px;
  text-decoration: none;
  text-align: center;
  color: #fff;
  background-color: #e1472b;
  border-radius: 36px;
}

.hamburger_menu {
  display: inline-block;
  position: absolute;
  right: 0;
  padding: 1em;
}

.hamburger_menu img {
  width: 32px;
}

section {
  padding: 0 20px;
}

.section_title {
  font-size: 1.8em;
  text-align: center;
  margin: 20px 0;
}

.activities_item {
  margin: 0 0 40px;
}

#work_schedule {
  margin: 0 0 40px;
}

.days {
  display: flex;
  line-height: 1.3em;
}



.days ul:last-of-type {
  list-style: none;
  padding-left: 1em;
}

.dinner {
  display: flex;
}

.dinner p {
padding-left: 10px;
text-align: center;
line-height: 1.5;
}

.activities_icon {
  width: 100px;
  height: 100px;
}

.work_schedule_icon {
width: 100px;
}

.contacts_icon {
  width: 30px;
  height: 30px;
  padding: 5px;
}

@media screen and (max-width: 760px) {
  #activities ul {
    padding: 0 0 0 20px;
  }
}

@media screen and (min-width: 360px) {
  section {
    padding:0 30px;
  }

  .header__title {
    font-size: 1.7em;
    padding-left: 20px;
  }
}

@media screen and (min-width: 760px) {
  section {
    padding-left: 40px;
  }
  
  .header {
    height: 5em;
    border-bottom: none;
  }

  .header__title {
    font-size: 2em;
  }

  .header_inner {
    display: flex;
    justify-content: center;
    font-size: 24px;
  }

  .hamburger_menu {
    display: none;
  }

  nav {
    height: 4em;
    padding: 5px 0 5px;
    background-color: #0d2436;
    border-bottom: 2px solid #e1472b;
    display: flex;
    justify-content: space-around;
  }

  nav ul {
    display: flex;
    flex-flow: wrap;
    justify-content: space-around;
    width: 95%;
  }

  img {
    width: 100px;
  }

  .activities_item {
    display: flex;
    flex-flow: wrap;
  }
  .activities_icon_wrapper {
    flex: 0 0 150px;
  }

  .activities_icon {
    width: 150px;
    height: 150px;
  }

  .activities_title {
    margin-left: 20px;  
  }

  .list_of_activities_wrapper {
    width: 70vw;
    max-width: 650px;
  }

  .list_of_activities {
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 auto;
    padding-left: 0;
  }
  
  .list_of_activities li {
    margin-left: 40px;
  }

  .social_links {
    width: 550px;
    display: flex;
    justify-content: space-between;
  }

  .work_schedule_wrapper {
    display: flex;
  }

  .work_schedule_icon {
    width: 150px;
    height: 150px;
  }

  .gallery_wrapper {
    text-align: center;
  }
}

@media screen and (min-width: 1024px) {
  .header {
    height: 7em;
  }

  .header__title {
    font-size: 3em;
  }

  nav ul {
    max-width: 1100px;
  }

  section {
    width: 900px;
    margin-left: auto;
    margin-right: auto;
  }

  .copyright {
    width: 900px;
    display: flex;
    justify-content: space-around;
    margin-left: auto;
    margin-right: auto;
    font-size: medium;
  }

}